import React, { useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from './Loader'
import { IoIosArrowRoundBack } from "react-icons/io";
import Footer from "./Footer";

function Add() {
  const URL = " https://beetruth.netlify.app/.netlify/functions/server";
  const [loader, setLoader] = useState(false)
  const title = useRef("");
  let platform = "";
  const url = useRef("");
  const navigate = useNavigate()
  const selectHandler = (e) => {
    platform = e.target.value;
  };
  const FormSubmission = async (e) => {
    e.preventDefault();
    const title1 = title.current.value;
    const post = url.current.value;
    if (!platform || !title1 || !post){
      e.target.reset();
      return toast.warning(`Please enter all value`, {
        autoClose: 3000,
      })};
    setLoader(true)
    try {
      const res = await fetch(`${URL}/add-${platform}-post`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("tokens")
            ? `Bearer ${localStorage.getItem("tokens")}`
            : null,
        },
        body: JSON.stringify({
          title: title.current.value,
          post: url.current.value,
          platform: platform,
        }),
      });
      const data = await res.json();
      toast.success(`Your Request has been submitted`, {
        position: "bottom-left",
        autoClose: "3000"
      });
      navigate("/");
    } catch (error) {
      toast.error(`Something went wrong due to ${error}`, {
        position: "top-left",
        autoClose: 3000,
      });
    }
    setLoader(false)
    e.target.reset();
  };
  return (
    <>
      <div className="add">
        <form className="col-md-4 mx-auto addform" onSubmit={FormSubmission}>
          <div className="addhead">
            <h3 className = "addhead_h3">Request a Post</h3>
            <NavLink className="nav-link" to="/">
            <IoIosArrowRoundBack className="nav-link_icons" />
            </NavLink>
          </div>
          <div className="mb-3">
            {/* <label htmlFor="exampleFormControlInput1" className="form-label">
              Title of your post
            </label> */}
            <input
              ref={title}
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Enter your title"
            ></input>
          </div>
          <div className="input-group mb-3">
            <select
              onChange={selectHandler}
              id="select"
              className="form-select"
              aria-label="Default select example"
              // defaultValue="insta"
            >
              <option>Select Platform</option>
              <option value="insta">Instagram</option>
              <option value="twitter">X(Twitter)</option>
              <option value="facebook">Facebook</option>
              <option value="tiktok">Tiktok</option>
            </select>
          </div>
          <input
            ref={url}
            type="text"
            className="form-control"
            aria-label="Text input with dropdown button"
            placeholder="Enter the Url of the post"
          ></input>
          <button type="Submit" className="btn btn-primary mt-2 admin_post_btn">
          {loader === false ? "Request To Post": <Loader />}  
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default Add;
