import { NavLink, useNavigate } from 'react-router-dom'
import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { toast } from 'react-toastify';
import  Loader from "./Loader"

function Login() {
    const [email, setEmail] = React.useState()
    const [password, setPassword] = React.useState()
    const [showPassword, setShowPassword] = React.useState(false);
    const [loader, setLoader] = useState(false)

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const navigate = useNavigate()
    const URL = " https://beetruth.netlify.app/.netlify/functions/server"

    React.useEffect(() => {
        if (localStorage.getItem("tokenss")) {
            navigate("/dashboard", { replace: true });
        }
    }, [navigate]);

    const btnHandler = async (e) => {
        e.preventDefault()
        const em = email
        const pass = password
        if (!em || !pass) return toast.warning('Please enter email and password', {
            autoClose: 3000
        });;
        setLoader(true)
        try {
            
            const response = await fetch(`${URL}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": true,
                },
                body: JSON.stringify({
                    email: em,
                    password: pass,
                }),
            });
           
            const data = await response.json()
            
            if (data.success === false) throw Error(data.message);
            localStorage.setItem("IsLoggedIn", 1)
            localStorage.setItem("tokenss", data.token)

            toast.success(`Welcome ${data.user.name}`, {
                position: "bottom-left",
                autoClose: 3000
            });
            navigate('/dashboard')
        } catch (error) {
            toast.error('Unauthorized', {
                autoClose: 3000
            });
            setLoader(false)
            
        }
    };

    return (
        <>
            <div className="add ">
                <div className="col-xxl-3 col-xl-3 mx-auto addform">
                    <div className="addhead pb-2">
                        <h3>Login</h3>
                    </div>
                    <form action="" onSubmit={btnHandler}>
                        <div className="addlabel mb-3">

                            <TextField
                                id="outlined-textarea"
                                label="Email"
                                style={{ width: '96%' }}
                                value={email}
                                onChange={(e) => setEmail((prev) => e.target.value)}

                            />
                        </div>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div style={{ width: '96%' }}>

                                <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">

                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(e) => setPassword((prev) => e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end"
                                            >
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>



                            </div>

                            <button style={{ width: '96%' }} type="submit" class="btn btn-primary mt-2 admin_post_btn" >{
                                loader === false ? "Login": <Loader />
                            }</button>
                        </Box>
                    </form>
                    <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                </div>
            </div>

        </>
    )
}

export default Login