import React, {useState } from 'react'
import axios from 'axios'
import { useNavigate  } from 'react-router-dom'
import { toast } from 'react-toastify'
import "./AddUser.css"
import Loader from "./Loader"

const AddUser = () => {
  let navigate = useNavigate();
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(false)
  const URL = " https://beetruth.netlify.app/.netlify/functions/server"


  const registerHandler = async (e) => {
    e.preventDefault()

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if (password !== confirmPassword) {
      setPassword("")
      setConfirmPassword("")
      setTimeout(() => {
        setError("")
      }, 5000);
      return setError("Password donot match")
    }
    setLoader(true)
    try {
      const { data } = await axios.post(
        `${URL}/register`,
        {
          name,
          email,
          password,
          confirmPassword
        },  config )

      localStorage.getItem("authToken", data.token)
      navigate("/allusers");
      toast.success(`User has been added successfully`, {
        toastId: "success",
        position: "bottom-left",
        autoClose: 4000,
      });

    }
    catch (error) {
      toast.error("Please try again or password should atleast of 6 character", {
        toastId: "error",
        position: "bottom-left",
        autoClose: 4000,
      });
    }
  }


  return (
    <>
      <div className="register-screen">
        <form onSubmit={registerHandler} className="register-screen__form">
          {error && <span className="error-message">{error}</span>}
          <div className="form-group">
            <label htmlFor="name">Username:</label>
            <input
              type="text"
              required
              id="name"
              placeholder="Enter username"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              required
              id="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              required
              id="password"
              autoComplete="true"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmpassword">Confirm Password:</label>
            <input
              type="password"
              required
              id="confirmpassword"
              autoComplete="true"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
          {
                                loader === false ? "Add User": <Loader />
                            }
          </button>
        </form>
      </div>
    </>
  )
}

export default AddUser