import Main from "./Main";
import Display from "./DisplayAgain";
import Add from "./Add";
import Dashboard from "./Dashboard";
import Fbtable from "./Fbtable";
import Instatable from "./Instatable";
import Userposts from "./Userposts";
import Tiktok from "./Tiktok";
import Twitter from "./TwitterTable";
import Adminpost from "./Adminpost";
import { Route, Routes } from "react-router-dom";
import Login from "./Login";
import PrivateRoutes from "./PrivaateRoutes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from "./DataTable";
import NotFound from './NotFound'
import AddUser from "./AddUser";
import Users from "./Users";

//#
function App() {
  // console.clear()
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route>
          <Route element={<PrivateRoutes />}>
            <Route path="adminpost" element={<Adminpost />} exact></Route>
            <Route path="dashboard" element={<Dashboard />} exact></Route>
            <Route path="twitter" element={<Twitter />} exact></Route>
            <Route path="facebook" element={<Fbtable />} exact></Route>
            <Route path="instagram" element={<Instatable />} exact></Route>
            <Route path="tiktok" element={<Tiktok />} exact></Route>
            <Route path="userposts" element={<Userposts />} exact></Route>
            <Route path="adduser" element={<AddUser />} exact></Route>
            <Route path="allusers" element={<Users />} exact></Route>
          </Route>
        </Route>
        <Route path="/" element={<Main />}></Route>
        <Route path="response" element={<Display />}></Route>
        {/* <Route path="report" element={<Display  pg={"report"}/>}></Route> */}
        <Route path="add" element={<Add />}></Route>
        <Route path="login" element={<Login />}></Route>
        <Route path="*" element={<NotFound />}></Route>
        {/* <Route path="datatable" element={<DataTable  name={'facebook'}/>}></Route> */}
      </Routes>
    </>
  );
}

export default App;

