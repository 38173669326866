import React, { useState } from "react";
function saveToLocalStorage(key, expirationHours) {
  const now = new Date();
  const item = {
    twitter: 0,
    fb: 0,
    insta: 0,
    tiktok: 0,
    expires: now.getTime() + expirationHours * 60 * 60 * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
  return JSON.stringify(item);
}
function Modal2(props) {
  const item = getFromLocalStorage("plat_prog");
  const [itemValue, setItemValue] = useState(item ? item[props.platform] : 20);
  function getFromLocalStorage(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expires) {
      localStorage.removeItem("plat_prog");
      saveToLocalStorage("plat_prog", 60);
      return null;
    }
    return item;
  }
  if (!item) {
    setItemValue(
      typeof saveToLocalStorage("plat_prog", 60) === "string"
        ? 20
        : saveToLocalStorage("plat_prog", 60)
    );
  }

  const btnHandler = () => {
    if (props.platform && item) {
      if (item[props.platform] <= 80) {
        item[props.platform] += 20;
        localStorage.setItem("plat_prog", JSON.stringify(item));
      }
      setItemValue(item[props?.platform]);
    }
    return;
  };
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="text-center modal-title">
                Your share means the world
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                1-Open the post by clicking the button below.<br></br>
              </p>
              <p>
                2-Tap the three dots icon located at the top of the post.
                <br></br>
              </p>
              <p>
                3-Click 'Report'.<br></br>
              </p>
              <p>
                4-Select ‘Hate speech or symbols’ as type of issue and click
                ‘Submit report’<br></br>
              </p>
              <p>
                5-Click ‘Close’<br></br>
              </p>
            </div>
            <div className="modal-footer">
              <a href={props.data} target="_blank">
                {props.pg?  (<button
                  className="dbtn"
                  data-bs-target="#exampleModalToggle2"
                  data-bs-toggle="modal"
                  onClick={btnHandler}
                >
                  <img src="chat2.png" alt="" />
                  <p className="mt-3">Report post</p>
                </button>):<button
                  className="dbtn"
                  data-bs-target="#exampleModalToggle2"
                  data-bs-toggle="modal"
                  onClick={btnHandler}
                >
                  <img src="chat2.png" alt="" />
                  <p className="mt-3">Reply on post</p>
                </button>}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalToggle2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggle "
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="text-center modal-title">
                Your share means the world
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className="progress"
              role="progressbar"
              aria-label="Warning example"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                className="progress-bar text-bg-warning"
                style={{ width: `${itemValue}%` }}
              >
                <b>{itemValue}%</b>
              </div>
            </div>
            <div className="modal-body">
              <p>
                Every post you share improves Palestine’s status on social
                media. You have the power to make a difference!
              </p>
            </div>
            <div className="modal-footer">
              <div className="bodybtns">
                <button
                  className="dbtn"
                  data-bs-dismiss="modal"
                  style={{ width: "95%" }}
                  aria-label="Close"
                  onClick={props.btnH}
                  
                >
                  <img src="chat2.png" className="mb-1" alt="" />
                  <p className="mt-3">Not finished yet, another post!</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal2;
