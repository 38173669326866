import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { FaXTwitter, FaInstagram, FaFacebook, FaTiktok } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { RiLogoutCircleLine } from "react-icons/ri";
import { FaRegAddressBook } from "react-icons/fa";

function ZPanel() {
    const navigate = useNavigate()
    const btnHandler = () => {
        localStorage.removeItem('tokenss')
        localStorage.removeItem('IsLoggedIn')
        navigate('/login')
    }
    const toggleSidebar = () => {
        document.body.classList.toggle("open");
    }
    return (
        <>
            <div className="dcol1">
                <nav class="sidebar col-xxl-12 col-xl-12 col-1g-11" id="side">
                    <div className="sidebar-inner">
                        <header class="sidebar-header">

                        </header>
                        <nav class="sidebar-menu">
                            <div className="beehead">
                                <NavLink className='nav-link' to='/dashboard'>

                                    <img id='bee' src="bee.png" className='mt-3' alt="" />
                                </NavLink>
                            </div>
                            <hr />

                            <div className="dasboard_menu_main">
                                <div className="dashboard_menu">
                                    <NavLink className="nav-link" to="/twitter"><FaXTwitter className="dashboard_menu_icon" />
                                    </NavLink>
                                </div>
                                <div className="dashboard_menu">
                                    <NavLink className="nav-link" to="/instagram"><FaInstagram className="dashboard_menu_icon1" />
                                    </NavLink>
                                </div>
                                <div className="dashboard_menu">
                                    <NavLink className="nav-link" to="/facebook"><FaFacebook className="dashboard_menu_icon2" /></NavLink>
                                </div>
                                <div className="dashboard_menu">
                                    <NavLink className="nav-link" to="/tiktok"><FaTiktok className="dashboard_menu_icon3" />
                                    </NavLink>
                                </div>
                                <div className="dashboard_menu">
                                    <NavLink className="nav-link" to="/userposts"><FaRegUser className="dashboard_menu_icon3" />
                                    </NavLink>
                                </div>
                                <div className="dashboard_menu">
                                    <NavLink className="nav-link" to="/allusers"><FaRegAddressBook
                                        className="dashboard_menu_icon3" />
                                    </NavLink>
                                </div>
                            </div>

                            <button type="button" id="sbutton" onClick={btnHandler}>
                                <NavLink className="nav-link"  ><RiLogoutCircleLine className="dashboard_menu_icon4" />
                                </NavLink>
                            </button>
                        </nav>
                    </div>
                </nav>
            </div>
            <div className="can">
                <div class="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                    <div class="offcanvas-header">
                        <h5 class="offcanvas-title" id="offcanvasExampleLabel">Be Truth Speaker</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <div className='content'>
                            <ul id='ul'>
                                <div className="dasboard_menu_main">
                                    <div className="dashboard_menu">
                                        <NavLink className="nav-link" to="/twitter"><FaXTwitter className="dashboard_menu_icon" />
                                        </NavLink>
                                    </div>
                                    <div className="dashboard_menu">
                                        <NavLink className="nav-link" to="/instagram"><FaInstagram className="dashboard_menu_icon1" />
                                        </NavLink>
                                    </div>
                                    <div className="dashboard_menu">
                                        <NavLink className="nav-link" to="/facebook"><FaFacebook className="dashboard_menu_icon2" /></NavLink>
                                    </div>
                                    <div className="dashboard_menu">
                                        <NavLink className="nav-link" to="/tiktok"><FaTiktok className="dashboard_menu_icon3" />
                                        </NavLink>
                                    </div>
                                    <div className="dashboard_menu">
                                        <NavLink className="nav-link" to="/userposts"><FaRegUser className="dashboard_menu_icon3" />
                                        </NavLink>
                                    </div>
                                    <div className="dashboard_menu">
                                        <NavLink className="nav-link" to="/allusers"><FaRegAddressBook
                                            className="dashboard_menu_icon3" />
                                        </NavLink>
                                    </div>
                                </div>


                                <hr />
                                <button type="button" id="sbutton" onClick={btnHandler}>
                                    <NavLink className="nav-link" ><span id='spam2' >Sign Out</span>
                                    </NavLink>
                                </button>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <button
                type="button"
                class="sidebar-burger"
                onClick={toggleSidebar}
                id="hambutton"
            ></button>
        </>
    )
}
export default ZPanel