import React, { useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import Togglebtn from "./Togglebtn";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import {
  FacebookEmbed,
  InstagramEmbed,
  TikTokEmbed,
  TwitterEmbed,
} from "react-social-media-embed";
import Dropdown from "./Dropdown";
import { toast } from "react-toastify";
import Modal2 from "./Modal2";
import Footer from "./Footer";
import LabelFooter from "./LabelFooter";

function Display(props) {

  const [queryParameters] = useSearchParams();
  const r = Boolean(queryParameters.get("rep"));
  const [platform, setPlatform] = useState();
  const [pg] = useState(props?.pg || r ? "report" : null);
  const [data, setData] = useState();
  const [fb, setFb] = useState();
  const [insta, setInsta] = useState();
  const [twitter, setTwitter] = useState();
  const [tiktok, setTiktok] = useState();
  const [fbIndex, setFbIndex] = useState(0);
  const [instaIndex, setInstaIndex] = useState(0);
  const [twitterIndex, setTwitterIndex] = useState(0);
  const [tiktokIndex, setTiktokIndex] = useState(0);
  const URL = "https://beetruth.netlify.app/.netlify/functions/server";

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${URL}/all-post`);
        const {
         data: [{ fb }, { twitter }, { tiktok }, { insta }],
        } = await response.json();     

        setFb(fb);
        setInsta(insta);
        setTiktok(tiktok);
        setTwitter(twitter)
        setPlatform("twitter")
      } catch (error) {
        toast.error(`Something went wrong due to ${error}`, {
          autoClose: 3000,
        });
      }
    }
    fetchData();
  }, []);



  const btnHandler = () => {
    if (platform === "fb") {
      if (fbIndex < fb.length - 1) {
        setFbIndex((prev) => prev + 1);
      } else {
        setFbIndex(0);
      }
      setData(fb[fbIndex + 1]?.post);
    } else if (platform === "insta") {
      if (instaIndex < insta.length - 1) {
        setInstaIndex((prev) => prev + 1);
      } else {
        setInstaIndex(0);
      }
      setData(insta[instaIndex + 1]?.post);
    } else if (platform === "twitter") {
      if (twitterIndex < twitter.length - 1) {
        setTwitterIndex((prev) => prev + 1);
      } else {
        setTwitterIndex(0);
      }
      console.log(twitter[twitterIndex + 1]?.post)
      setData(twitter[twitterIndex + 1]?.post);
    } else if (platform === "tiktok") {
      if (tiktokIndex < tiktok.length - 1) {
        setTiktokIndex((prev) => prev + 1);
      } else {
        setTiktokIndex(0);
      }
      setData(tiktok[tiktokIndex + 1]?.post);
    }
  };

  useEffect(() => {
    if (platform === "fb") setData(fb ? fb[fbIndex]?.post : "");
    if (platform === "insta") {
      setData(insta ? insta[instaIndex]?.post : "");
    }
    if (platform === "twitter")
      setData(twitter ? twitter[twitterIndex].post : "")
    if (platform === "tiktok") setData(tiktok ? tiktok[tiktokIndex]?.post : "");
  }, [platform, fbIndex, instaIndex, twitterIndex, tiktokIndex]);

  return (
    <>
      <div className="dmain">
        <div className="dbox col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-sm-9 mx-auto">
          <div className="top">
            <div className="timg">
              <img src="bee.png" alt="display-content" />
            </div>
            <Dropdown />
            <Togglebtn />
          </div>
          <div className="socialmedia mt-2">
            <NavLink href="">
              <div className="mbox">
                <div
                  className="iconbox"
                  style={
                    platform == "twitter"
                      ? { backgroundColor: "#e9ecf0be" }
                      : null
                  }
                  id="navli"
                  tabIndex="1"
                  onClick={() => setPlatform("twitter")}
                >
                  <img src="tw.png" alt="display-content" />
                </div>
              </div>
            </NavLink>
            <div className="line"></div>
            <NavLink href="">
              <div className="mbox">
                <div
                  className="iconbox"
                  tabIndex="1"
                  onClick={() => setPlatform("insta")}
                >
                  <img src="insta2.png" id="insta" alt="display-content" />
                </div>
              </div>
            </NavLink>
            <div className="line"></div>
            <NavLink href="">
              <div className="mbox">
                <div
                  className="iconbox"
                  tabIndex="1"
                  onClick={() => setPlatform("tiktok")}
                >
                  <img src="tiktok.png" alt="display-content" />
                </div>
              </div>
            </NavLink>
            <div className="line"></div>
            <NavLink href="">
              <div className="mbox">
                <div
                  className="iconbox"
                  tabIndex="1"
                  onClick={() => setPlatform("fb")}
                >
                  <img src="fb.png" alt="display-content" />
                </div>
              </div>
            </NavLink>
          </div>

          {/*  */}
          <div className="bodybtns">
            {pg ? (
              <button
                className="dbtn"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
              >
                <img src="chat2.png" className="mb-1" alt="content" />
                {/* <NavLink to = {`${data.post}`}>Repost</NavLink> */}
                <p className="mt-2">Report Post</p>
              </button>
            ) : (

              <button
                className="dbtn"
              >
                <img src="chat2.png" className="mb-1" alt="content" />
                <NavLink to = {`${data}` } 
                rel="noopener" target="_blank" 
                className="navlink_repost">Repost</NavLink>
              </button>
            )}
            <button className="dbtn2" onClick={btnHandler}>
              <div id="dbtn3img"></div>
              <p className="mt-3">New Post</p>
            </button>
          </div>
          {/*  */}

          <div className="dbody mt-2">

            {/* )} */}
            <div className="mpost" key={data}>
              {platform === "insta" && data && (
                <InstagramEmbed url={data} className="mpost_twitter" />

              )}
              {/*  width={400} height={740}  */}
              {/*  width={400} height={730} */}
              {platform === "twitter" && data && (
                <TwitterEmbed url={data} className="mpost_twitter" />
              )}
              {/* width={400} height={400} */}
              {platform === "fb" && data && (
                <FacebookEmbed url={data} className="mpost_twitter" />
              )}
              {platform === "tiktok" && data && (
                <TikTokEmbed url={data} className="mpost_twitter" />
              )}
              {/* width={400} height={740}  */}
            </div>
          </div>

          {/* <div className="bodybtns">
            {pg ? (
              <button
                className="dbtn"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
              >
                <img src="chat2.png" className="mb-1" alt="content" />
                <p className="mt-2">Report Post</p>
              </button>
            ) : (
              <button
                className="dbtn"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
              >
                <img src="chat2.png" alt="display-content" />
                <p className="mt-3">Reply on a post</p>
              </button>
            )}
            <button className="dbtn2" onClick={btnHandler}>
              <div id="dbtn3img"></div>
              <p className="mt-3">New Post</p>
            </button>
          </div> */}

          {pg ? (
            <div className="bodybtns">
              <button
                className="dbtn"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
                style={{ width: "100%" }}
              >
                <img src="chat2.png" className="mb-1" alt="content" />
                <p className="mt-2">Report Post</p>
              </button>
            </div>
          ) : (
            <div className="bodybtns">
              <button
                className="dbtn"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
              >
                <img src="chat2.png" alt="display-content" />
                <p className="mt-3">Reply on a post</p>
              </button>

              {/* <button className="dbtn2">
                <div id="dbtn2img"></div>
                <p className="mt-3">Auto Pilot</p>
              </button> */}
            </div>
          )}


          <div className="socialmedia2">
            <div className="sul">
              <span>
                <p>
                  <span>
                    <img src="dot.png" alt="" />
                  </span>
                  Stay Updated
                </p>
              </span>
              <span>
                <p>
                  <span>
                    <img src="dot.png" alt="" />
                  </span>
                  Share Feedback
                </p>
              </span>
              <button
                className="flex items-center gap-1"
                id="language-switcher-footer"
                type="button"
                aria-haspopup="dialog"
                aria-expanded="false"
                aria-controls="radix-:R3dfbrplta:"
                data-state="closed"
              >
                <svg
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current rtl:scale-x-[-1] rtl:transform w-[24px] h-[24px] rounded-full aspect-square overflow-hidden"
                >
                  <path
                    fill="#ed4c5c"
                    d="M48 6.6C43.3 3.7 37.9 2 32 2v4.6h16"
                  ></path>
                  <path
                    fill="#fff"
                    d="M32 11.2h21.6C51.9 9.5 50 7.9 48 6.6H32v4.6z"
                  ></path>
                  <path
                    fill="#ed4c5c"
                    d="M32 15.8h25.3c-1.1-1.7-2.3-3.2-3.6-4.6H32v4.6z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M32 20.4h27.7c-.7-1.6-1.5-3.2-2.4-4.6H32v4.6"
                  ></path>
                  <path
                    fill="#ed4c5c"
                    d="M32 25h29.2c-.4-1.6-.9-3.1-1.5-4.6H32V25z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M32 29.7h29.9c-.1-1.6-.4-3.1-.7-4.6H32v4.6"
                  ></path>
                  <path
                    fill="#ed4c5c"
                    d="M61.9 29.7H32V32H2c0 .8 0 1.5.1 2.3h59.8c.1-.8.1-1.5.1-2.3 0-.8 0-1.6-.1-2.3"
                  ></path>
                  <path
                    fill="#fff"
                    d="M2.8 38.9h58.4c.4-1.5.6-3 .7-4.6H2.1c.1 1.5.3 3.1.7 4.6"
                  ></path>
                  <path
                    fill="#ed4c5c"
                    d="M4.3 43.5h55.4c.6-1.5 1.1-3 1.5-4.6H2.8c.4 1.6.9 3.1 1.5 4.6"
                  ></path>
                  <path
                    fill="#fff"
                    d="M6.7 48.1h50.6c.9-1.5 1.7-3 2.4-4.6H4.3c.7 1.6 1.5 3.1 2.4 4.6"
                  ></path>
                  <path
                    fill="#ed4c5c"
                    d="M10.3 52.7h43.4c1.3-1.4 2.6-3 3.6-4.6H6.7c1 1.7 2.3 3.2 3.6 4.6"
                  ></path>
                  <path
                    fill="#fff"
                    d="M15.9 57.3h32.2c2.1-1.3 3.9-2.9 5.6-4.6H10.3c1.7 1.8 3.6 3.3 5.6 4.6"
                  ></path>
                  <path
                    fill="#ed4c5c"
                    d="M32 62c5.9 0 11.4-1.7 16.1-4.7H15.9c4.7 3 10.2 4.7 16.1 4.7"
                  ></path>
                  <path
                    fill="#428bc1"
                    d="M16 6.6c-2.1 1.3-4 2.9-5.7 4.6-1.4 1.4-2.6 3-3.6 4.6-.9 1.5-1.8 3-2.4 4.6-.6 1.5-1.1 3-1.5 4.6-.4 1.5-.6 3-.7 4.6-.1.8-.1 1.6-.1 2.4h30V2c-5.9 0-11.3 1.7-16 4.6"
                  ></path>
                  <path
                    fill="#fff"
                    d="M25 3l.5 1.5H27l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zm4 6l.5 1.5H31l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zm-8 0l.5 1.5H23l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zm4 6l.5 1.5H27l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zm-8 0l.5 1.5H19l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zm-8 0l.5 1.5H11l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zm20 6l.5 1.5H31l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zm-8 0l.5 1.5H23l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zm-8 0l.5 1.5H15l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zm12 6l.5 1.5H27l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zm-8 0l.5 1.5H19l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zm-8 0l.5 1.5H11l-1.2 1 .4 1.5-1.2-.9-1.2.9.4-1.5-1.2-1h1.5zm2.8-14l1.2-.9 1.2.9-.5-1.5 1.2-1h-1.5L13 9l-.5 1.5h-1.4l1.2.9-.5 1.6m-8 12l1.2-.9 1.2.9-.5-1.5 1.2-1H5.5L5 21l-.5 1.5h-1c0 .1-.1.2-.1.3l.8.6-.4 1.6"
                  ></path>
                </svg>
                English
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="h-4 w-4"
                >
                  <path d="m7 15 5 5 5-5"></path>
                  <path d="m7 9 5-5 5 5"></path>
                </svg>
              </button>
              {/* <p><span>.</span>Stay Updated</p> */}
            </div>
           
            <div className="fimages">
              <FaTwitter className="fimages_icons" />
              <FaLinkedin className="fimages_icons" />
              <FaFacebook className="fimages_icons" />
              <FaInstagram className="fimages_icons" />
              <FaTiktok className="fimages_icons" />

              {/* <img src="x.png.jpeg" alt="" />
              <img src="fac.png.jpeg" alt="" />
              <img src="ins.png.jpeg" alt="" />
              <img src="ti2.png.jpeg" alt="" />
              <img src="li2.png.jpeg" alt="" /> */}
            </div>
            
            <LabelFooter />
          </div>


        </div>
      </div>
      <Modal2
        data={data ? data : ""}
        platform={platform ? platform : ""}
        pg={pg}
        btnH={btnHandler}
      />

     
    </>
  );
}

export default Display;
