import React, { useState, useEffect } from 'react'
import axios from 'axios';
import ZPanel from './ZPanel1111';
import { NavLink } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { toast } from 'react-toastify';
import './Users.css'
import Table from 'react-bootstrap/Table';


function Users() {
    const [userData, setUserData] = useState([])
    const URL = " https://beetruth.netlify.app/.netlify/functions/server";


    const getUsers = () => {
        axios.get(
            `${URL}/admin/all-users`, {
            headers: {
                Authorization: `${localStorage.getItem("tokenss")}`

            }
        })
            .then((response) => {
                console.log(response.data.users)
                setUserData(response.data.users)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    useEffect(() => {
        getUsers()

    }, [])

    const deleteUser = (id) => {
        axios.delete(`${URL}/admin/delete-user/${id}`, {
            headers: {
                Authorization: `${localStorage.getItem("tokenss")}`

            }
        }).then((response) => {
            toast.success("User deleted successfully", {
                autoClose: 3000,
                position: "bottom-left"
            })
            getUsers()
        }).catch((e) => {
            toast.error(`Something went wrong ${e}`, {
                autoClose: 3000,
                position: "bottom-left"
            })
        })
    }

    return (
        <>
            <div className="dashboardx">

                <div className="dbodyx">
                    <ZPanel />
                    <div className="reqformx">
                        {" "}
                        <NavLink to="/adduser" className="nav-link"></NavLink>
                        <div className="text-center col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-11 mx-auto mt-2 mainreqx">
                            <div className="btnhead">
                                <span>
                                    <a
                                        data-bs-toggle="offcanvas"
                                        href="#offcanvasExample"
                                        role="button"
                                        aria-controls="offcanvasExample"
                                    >
                                        <img src="ham.png" id="sham" alt="menu-bar" />
                                    </a>
                                </span>
                                <NavLink to="/adduser">
                                    <button
                                        type="button"
                                        className="btn btn-primary mt-2 admin_post_btn"
                                        id="postbtn"
                                    >
                                        Add User
                                    </button>
                                </NavLink>
                            </div>
                            <div className="tablecontainer">
                                
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th scope="col" id="colborder">
                                                    <p>S.no</p>
                                                </th>
                                                <th scope="col" id="colborder">
                                                    <p>Name</p>
                                                </th>
                                                <th scope="col" id="colborder">
                                                    <p>Email</p>
                                                </th>
                                                <th scope="col" id="colborder">
                                                    <p>Action</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userData.map((item, index) => (
                                                <tr key={item._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.email}</td>
                                                    <td>
                                                        <RxCross2
                                                            onClick={() => deleteUser(item._id)}
                                                            className='delete_user'
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default Users;
// {isData && <tbody>{data}</tbody>}