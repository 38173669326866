import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            <div className="footer">
                <h3 className="footer_h3">Powered by <span className="footer_h3_span">
                    <NavLink to="https://techxudo.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >techxudo
                    </NavLink>
                </span>
                </h3>
            </div>
        </>
    )
}

export default Footer