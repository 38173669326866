import React from 'react'
import { NavLink } from 'react-router-dom'

const LabelFooter = () => {
    return (
        <>
            <div className="footer1">
                <h3 className="footer1_h3">Powered by <span className="footer1_h3_span">
                    <NavLink to="https://techxudo.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >techxudo
                    </NavLink>
                </span>
                </h3>
            </div>

        </>

    )
}

export default LabelFooter