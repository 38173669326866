import React from "react";

import { NavLink, useNavigate } from "react-router-dom";

function Dropdown() {
  const navigate = useNavigate();
  const btn1 = () => {
    navigate("/report");
    window.location.reload(true);
  };
  const btn2 = () => {
    navigate("/response");
    window.location.reload(true);
  };
  const btn3 = () => {
    navigate("/add");
    window.location.reload(true);
  };
  return (
    <>
      <li className="nav-item dropdown" id="drop">
        <p
          className="nav-link mt-3 dropdown-toggle"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ color: "white" }}
        >
          Supporting Content
        </p>
        <ul className="dropdown-menu" id="dropmenu" >
          <NavLink
            className="dropdown-item"
            to="/response"
            onClick={btn2}
            style={{ width: "100%" }}
          >
            <li id="drop2">
              <img src="share2.png" alt="share-content" /> Share a response
            </li>
          </NavLink>
          {/* <NavLink className="dropdown-item" onClick={btn1} style={{ width: "100%" }}>
            <li id="drop2">
              <img src="report.png" alt="share-content" /> Report a content
            </li>
          </NavLink> */}
          <NavLink
            className="dropdown-item"
            to="/add"
            onClick={btn3}
            style={{ width: "100%" }}
          >
            <li id="drop2">
              <img src="add.png" alt="share-content" /> Add a post
            </li>
          </NavLink>
        </ul>
      </li>
    </>
  );
}

export default Dropdown;
