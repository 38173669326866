import React, { useEffect, useState } from "react";
import ZPanel from "./ZPanel1111";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";

function Userposts() {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isData, setIsData] = useState(null);
  const URL = " https://beetruth.netlify.app/.netlify/functions/server";

  useEffect(() => {
    async function call() {
      try {
        const res = await fetch(`${URL}/admin/all-post`, {
          headers: {
            "Content-Type": "application/json",
            auth_token: localStorage.getItem("tokenss")
              ? `Bearer ${localStorage.getItem("tokenss")}`
              : null,
          },
        });
        const data = await res.json();
        setPosts(data.data);
        if (data.data.length > 0) {
          setIsData(true);
        } else {
          setIsData(false);
        }
        setIsLoading(false);
      } catch (err) {
        toast.error(`Something went wrong due to ${err}`, {
          autoClose: 3000,
        });
      }
    }
    call();
    return () => {};
  }, []);
  const handleAction = async (postId, action, notify) => {
    const p_id = posts[postId]?._id;
    try {
      const res = await fetch(`${URL}/admin/action/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          auth_token: localStorage.getItem("tokenss")
            ? `Bearer ${localStorage.getItem("tokenss")}`
            : null,
        },
        body: JSON.stringify({ action, id: p_id }),
      });

      const data = await res.json();
      setPosts((prevPosts) => prevPosts.filter((post) => post._id !== data.id));

      if (notify) {
        toast.success("Post Approved", {
          position: "bottom-left",
          autoClose: 3000,
        });
      } else {
        toast.error("Post Rejected", {
          position: "bottom-left",
          autoClose: 3000,
        });
      }
    } catch (err) {
      toast.error(`Something went wrong due to ${err}`, {
        position: "bottom-left",
        autoClose: 3000,
      });
    }
  };
  const data = posts?.map((data, index) => {
    return (
      <tr key={index}>
        <td colSpan="1" className="change" id="colborder">
          <p>{index + 1}</p>
        </td>
        <td colSpan="1" id="colborder">
          <p>{data.title}</p>
        </td>
        <td colSpan="1" id="colborder">
          <p>{data.platform}</p>
        </td>
        <td colSpan="1" id="colborder">
          <a href={data.post}>link</a>
        </td>
        <td colSpan="1" id="lastcol">
          <button
            className="btn btn-primary mt-2"
            id={index}
            onClick={() => handleAction(index, "approved", true)}
          >
            Approve
          </button>
          <button
            className="btn btn-danger mt-2"
            id={index}
            onClick={() => handleAction(index, "delete")}
          >
            Reject
          </button>
        </td>
      </tr>
    );
  });
  return (
    <>
      <div className="dashboardx">
        <div className="dbodyx">
          <ZPanel />
          <div className="reqformx">
            <div className="btnhead mt-1">
              <span>
                <a
                  data-bs-toggle="offcanvas"
                  href="#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                >
                  <img src="ham.png" id="sham" alt="menu-bar" />
                </a>
              </span>
            </div>

            <div className="text-center col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-11 mx-auto mt-2 mainreqx">
              <div className="mt-2 backshadow">
                <div className="header">
                  <h2>User Posts</h2>
                </div>
                <div className="tablecontainer">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" id="colborder">
                          <p>S.no</p>{" "}
                        </th>
                        <th scope="col" id="colborder">
                          <p>Title</p>{" "}
                        </th>
                        <th scope="col" id="colborder">
                          <p>Platform</p>
                        </th>
                        <th scope="col" id="colborder">
                          <p>Url</p>
                        </th>
                        <th scope="col" id="colborder">
                          <p>Action</p>
                        </th>
                      </tr>
                    </thead>
                    {isData && <tbody>{data}</tbody>}
                  </table>
                  {!isLoading && !isData && (
                    <h2 style={{ textAlign: "center" }}>No Posts </h2>
                    )}
                    {isLoading && (
                      <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={["#149954", "#149954", "#149954", "#000000"]}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Userposts;

// isLoading && (
// );
